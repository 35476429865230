<template>
  <fragment>
    <delivery-type/>
    <delivery-info/>
  </fragment>
</template>

<script>
import CartDeliveryType from "./CartDeliveryType";
import CartDeliveryInfo from "./CartDeliveryInfo";

export default {
  components: {
    'delivery-type': CartDeliveryType,
    'delivery-info': CartDeliveryInfo
  },
  name: "CartDelivery"
}
</script>

<style scoped>

</style>