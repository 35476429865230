<template>
  <fragment>
    <div class="container full-section mb-5" v-if="((cartLines && cartLines.length > 0) || (longTermLines && longTermLines.length)) && isCartLoaded">
      <h1 class="mt-3">Корзина</h1>
      <cart-content :long-term-lines="longTermLines" :lines="cartLines"/>
    </div>
    <empty-cart v-if="isCartLoaded && (!cartLines || cartLines.length === 0) && (!longTermLines || longTermLines.length === 0)"/>
  </fragment>
</template>

<script>
import CartContent from "./CartContent";
import EmptyCart from './EmptyCart'
import {mapGetters} from "vuex";

export default {
  name: "Cart",
  components: {
    EmptyCart,
    CartContent
  },
  computed: {
    ...mapGetters('cart/subdivision', ['isCartLoaded', 'cartLines', 'longTermLines'])
  }
}
</script>

<style scoped>
h3 {
  padding-top: 30px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .container:not(.notification) {
    max-width: 1000px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .container:not(.notification) {
    max-width: 950px;
  }
}
</style>
