<template>
  <fragment>
    <div class="row mob-cart">
      <div class="col-md-8">
        <p class="isInvalid mt-4">
          Данные указаны неверно?
          <router-link :to="{name: 'Contacts'}"><u>Свяжитесь с нами!</u></router-link>
        </p>
        <div class="d-flex align-items-center mb-2" v-if="anyProductBalanceIsNotEnough">
          <span class="icon-error float-left mr-2"></span>
          <p class="notify-note">
            На Вашем счете недостаточно средств, сократите, пожалуйста, заказ, удалив или изменив количество позиций в
            вашем заказе.
          </p>
        </div>
        <div class="d-flex align-items-center mb-2" v-if="!areSameAgenciesProducts">
          <span class="icon-error float-left mr-2"></span>
          <div>
            <p class="notify-note">Доставка осуществляется двумя разными партнерами в зависимости от категории
              товара.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-md-right text-center mt-3">
        <p class="result">Итого: {{ getTotal }}</p>
      </div>
    </div>

    <div class="row mob-cart">
      <div class="col text-md-right text-center mt-md-3">
        <div class="form-group">
          <button :disabled="!canCreateOrder" @click="createOrder" class="btn btn-default">Оформить заказ
          </button>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CartInfo",
  computed: {
    ...mapGetters('cart/subdivision', ['anyProductBalanceIsNotEnough', 'areSameAgenciesProducts', 'getTotal', 'canCreateOrder'])
  },
  methods: {
    ...mapActions('cart/subdivision', ['createOrder'])
  }
}
</script>

<style scoped>
.result {
  font-size: 18px;
  font-weight: bold;
}

.notify-note {
  font-size: 12px;
  line-height: normal;
  margin-bottom: 0;
}

.icon-error {
  display: inline-block;
  background-repeat: no-repeat;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
}

.icon-error {
  background-image: url("../../assets/images/alarm.svg");
}

@media (max-width: 767px) {
  .mob-cart {
    padding: 20px 0;
  }

  .icon-error {
    margin-bottom: 20px;
  }

  .isInvalid {
    font-size: 12px;
    text-align: left;
    color: #4a4a4a;
  }
}
</style>
