<template>
  <div>
    <div class="product-grid d-flex justify-content-md-center">
      <div class="ss-header">
        <span class="title-desc"><strong>Корзина пуста.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyCart"
}
</script>

<style scoped>

</style>