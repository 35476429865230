<template>
  <fragment>
    <div class="cartSection">
      <div class="vertical-imgs">
        <img alt="product main photo" class="item" :src="getProductImageSrc(line.productImageId)">
      </div>
    </div>
    <div class="cartSection pt-2">
      <p class="infoHead">Товар</p>
      <p> {{ line.productName }} <span v-if="line.quantityInBox > 1">({{line.quantityInBox}} шт.)</span> <span v-if="line.sizeName" class="size-name">{{line.sizeName}}</span></p>
      <p class="infoHead">Количество</p>
      <div class="d-table mb-3">
        <product-quantity :product-id="line.productId" :size-id="line.sizeId" :quantity="line.quantity"/>
        <div class="row ml-0 min-order-block" v-if="line.quantity < line.minimumCountForOrder">
          <span class="min-order">мин. заказ: {{ line.minimumCountForOrder }} шт.</span>
        </div>
      </div>
      <p class="infoHead"> Баланс</p>
      <p> {{ line.structuralSubdivisionProductBalance }} </p>
    </div>
    <div class="cartSection removeWrap text-center align-middle">
      <button class="close-oval" @click="deleteLine({productId: line.productId, sizeId: line.sizeId})" :disabled="isCartUpdating"><span class="close">&times;</span>
      </button>
    </div>
  </fragment>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CartLineProductQuantityMobile from "./CartLineProductQuantityMobile";

export default {
  name: "CartLineMobile",
  components: {
    'product-quantity': CartLineProductQuantityMobile
  },
  props: {
    line: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('common', ['getProductImageSrc']),
    ...mapGetters('cart/subdivision', ['isCartUpdating']),
  },
  methods: {
    ...mapActions('cart/subdivision', ['deleteLine'])
  }
}
</script>

<style scoped>
h3 {
  padding-top: 30px;
}

.item {
  width: 100%;
  height: 100%;
  /*margin-top: 16px;*/
}

.table thead th {
  border: none !important;
}

.close-oval {
  width: 26px;
  height: 26px;
  background-color: #4a4a4a;
  border: none;
  border-radius: 50%;
  margin-top: 6px;
  cursor: pointer;
}

.close {
  font-size: 20px;
  font-weight: normal;
  font-family: Serif;
  color: white;
  position: relative;
  opacity: 1;
  top: -1px;
  text-align: center;
  left: -1px;
}

td {
  font-weight: 600;
  font-size: 15px;
  vertical-align: middle;
  font-size: 16px;
}

.vertical-imgs {
  width: 60px;
  height: 77px;
}

.infoHead {
  font-size: 14px;
  border-top: 0;
  font-weight: bold;
}


@media (max-width: 767px) {
  .cartSection {
    display: table-cell;
  }

  .cartSection:first-child {
    vertical-align: middle;
    width: 70px;
  }


  .vertical-imgs {
    margin: 50px 70px 50px 20px;
  }
}


.min-order-block {
  display: block;
  clear: both;
}

.min-order {
  font-size: .8em;
  font-weight: bold;
  color: red;
}
</style>