<template>
  <table class="table prod-table">
    <thead>
    <tr>
      <th></th>
      <th>Товар</th>
      <th width="250px">Kоличество</th>
      <th>Баланс</th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="lines.length && longTermLines.length">
      <td colspan="6">
        <h2 class="table-subtitle">Товары со стандартным сроком производства</h2>
      </td>
    </tr>
    <cart-line v-for="(line, key) in lines" :line="line" :key="key + line.quantity"/>
    <tr v-if="lines.length && longTermLines.length">
      <td colspan="6">
        <h2 class="table-subtitle">Товары длительного производства</h2>
      </td>
    </tr>
    <cart-line v-for="(line, key) in longTermLines" :line="line" :key="key" />
    </tbody>
  </table>
</template>

<script>
import CartLineDesktop from "./CartLineDesktop";

export default {
  name: "CartLinesDesktop",
  components: {
    'cart-line': CartLineDesktop
  },
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style scoped>
.table thead th {
  border: none !important;
}

.table-subtitle {
  font-size: 1.1rem;
  font-weight: bold;
}

th {
  font-weight: 700;
  font-size: 12px;
}

.table td, .table th {
  border: none;
  border-bottom: 1px solid rgba(179, 179, 179, 0.35);
}

.table thead th {
  font-size: 14px;
  border-top: 0;
  font-weight: bold;
}

@media (max-width: 767px) {
  .table {
    display: none;
  }
}
</style>
