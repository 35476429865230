<template>
<fragment>
  <lines-desktop :long-term-lines="longTermLines" :lines="lines"/>
  <lines-mobile :long-term-lines="longTermLines" :lines="lines"/>
  <delivery/>
  <cart-info/>
</fragment>
</template>

<script>
import CartLinesDesktop from "./CartLinesDesktop";
import CartLinesMobile from "./CartLinesMobile";
import CartDelivery from "./CartDelivery";
import CartInfo from "./CartInfo";

export default {
  name: "CartContent",
  components: {
    'lines-desktop': CartLinesDesktop,
    'lines-mobile': CartLinesMobile,
    'delivery': CartDelivery,
    'cart-info': CartInfo
  },
  props: {
    longTermLines: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style scoped>

</style>
