<template>
    <div class="row mob-cart pb-4" v-if="deliveryType">
        <div class="col-sm-6 col-md-4">
            <input
                type="text"
                class="delivery-field delivery-text-input"
                v-if="isSelectedDeliveryToOtherRegion"
                :value="deliveryRegion"
                @input="setDeliveryInfo('region', $event.target.value)"
                :placeholder="'Регион/Область *'"
                :class="{
                    'delivery-field-required': isFieldEmpty(deliveryRegion),
                }"
                name="regionInput"
            />
            <input
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryCity"
                :disabled="!isSelectedDeliveryToOtherRegion"
                @input="setDeliveryInfo('city', $event.target.value)"
                :placeholder="'Город *'"
                :class="{
                    'delivery-field-required': isFieldEmpty(deliveryCity),
                }"
                name="cityInput"
            />
            <input
                v-if="isSelectedDeliveryToTheOffice"
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryAddress"
                :disabled="isSelectedDeliveryToTheOffice"
                @input="setDeliveryInfo('address', $event.target.value)"
                :placeholder="'Адрес получателя *'"
                :class="{
                    'delivery-field-required': isFieldEmpty(deliveryAddress),
                }"
                name="addressInput"
            />
            <input
                v-if="!isSelectedDeliveryToTheOffice"
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryStreet"
                @input="setDeliveryInfo('street', $event.target.value)"
                :placeholder="'Улица *'"
                :class="{
                    'delivery-field-required': isFieldEmpty(deliveryStreet),
                }"
                name="streetInput"
            />
            <input
                v-if="!isSelectedDeliveryToTheOffice"
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryHouse"
                @input="setDeliveryInfo('house', $event.target.value)"
                :class="{
                    'delivery-field-required': isFieldEmpty(deliveryHouse),
                }"
                :placeholder="
                    isFieldEmpty(deliveryRecipientEmail)
                        ? 'Дом получателя заказа должен быть указан*'
                        : 'Дом*'
                "
                name="houseInput"
            />
            <input
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryRecipientName"
                @input="setDeliveryInfo('recipientName', $event.target.value)"
                :placeholder="'ФИО получателя *'"
                :class="{
                    'delivery-field-required': isFieldEmpty(
                        deliveryRecipientName
                    ),
                }"
                name="recipientNameInput"
            />

            <input
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryRecipientEmail"
                @input="setDeliveryInfo('recipientEmail', $event.target.value)"
                :placeholder="
                    isFieldEmpty(deliveryRecipientEmail)
                        ? 'Email получателя заказа должен быть указан*'
                        : 'Email получателя заказа*'
                "
                :class="{
                    'delivery-field-required': isFieldEmpty(
                        deliveryRecipientEmail
                    ),
                }"
                name="recipientEmailInput"
            />
            <div class="d-none d-md-block">
                <input
                    type="checkbox"
                    class="order-urgent-checkbox"
                    name="isOrderUrgentInput"
                    id="isOrderUrgent"
                    :value="deliveryIsOrderUrgent"
                    @change="
                        setDeliveryInfo('isOrderUrgent', $event.target.checked)
                    "
                />
                <label for="isOrderUrgent" class="order-urgent-checkbox-label">Срочный заказ?</label>
            </div>
            <div v-if="isSplitOrder" class="del__long-term-production mt-4">
                <h3 class="long-term__title pt-0">В вашем заказе есть товары длительного производства. Выберите вариант доставки:</h3>
                <label class="control control--radio ng-star-inserted">
                    Получить все товары одним грузом
                    <div class="long-term__message">
                        <DangerIcon />
                        Выбирая данный вариант доставки, Вы соглашаетесь с длительным ожиданием всего заказа (от 40 рабочих дней).
                    </div>
                    <input
                        type="radio"
                        name="splitOrder"
                        :checked="splitOrder === false"
                        :value="false"
                        @change="setDeliveryInfo('splitOrder', false)"
                    />
                    <div class="control__indicator"></div>
                </label>
                <label class="control control--radio ng-star-inserted">
                    Разделить заказ на 2 части
                    <div class="long-term__message">
                        <DangerIcon />
                        Будет сформировано 2 заказа и выставлено 2 счета для оплаты. Доставка заказов будет осуществляться по мере производства.
                    </div>
                    <input
                        type="radio"
                        name="splitOrder"
                        :checked="splitOrder === true"
                        :value="true"
                        @change="setDeliveryInfo('splitOrder', true)"
                    />
                    <div class="control__indicator"></div>
                </label>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="mb-4">
                <input
                    type="text"
                    class="delivery-field delivery-text-input"
                    :value="deliveryContactNumber"
                    @input="
                        setDeliveryInfo('contactNumber', $event.target.value)
                    "
                    :class="{
                        'delivery-field-required': isFieldEmpty(
                            deliveryContactNumber
                        ),
                    }"
                    :placeholder="'Мобильный номер телефона получателя *'"
                    v-mask="'+7 (###) ###-##-##'"
                    name="contactNumberInput"
                />
                <div class="d-flex align-items-center">
                    <span class="icon-error float-left mr-2"></span>
                    <p class="notify-note">
                        При указании городского номера телефона заявка в работу
                        не принимается
                    </p>
                </div>
            </div>
            <input
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryOptionalContactNumber"
                @input="
                    setDeliveryInfo(
                        'optionalContactNumber',
                        $event.target.value == '' ? null : $event.target.value
                    )
                "
                name="optionalContactNumberInput"
                v-mask="'+7 (###) ###-##-##'"
                placeholder="Доп. телефон получателя"
            />

            <input
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryOrderOrganization"
                @input="
                    setDeliveryInfo('orderOrganization', $event.target.value)
                "
                :disabled="isSelectedDeliveryToTheOrganization"
                :placeholder="'Организация получателя *'"
                :class="{
                    'delivery-field-required': isFieldEmpty(
                        deliveryOrderOrganization
                    ),
                }"
                name="orderOrganizationInput"
            />

            <input
                type="text"
                class="delivery-field delivery-text-input"
                :value="deliveryOrderPurpose"
                @input="setDeliveryInfo('orderPurpose', $event.target.value)"
                :placeholder="'Цель заказа *'"
                :class="{
                    'delivery-field-required':
                        isFieldEmpty(deliveryOrderPurpose),
                }"
                name="orderPurposeInput"
            />
            <div class="vertical-line"></div>
            <input
                type="text"
                class="delivery-field delivery-text-input"
                name="userEmailInput"
                :value="userEmail"
                disabled
            />
            <input
                type="text"
                class="delivery-field delivery-text-input"
                name="userPhoneNumberInput"
                :value="userPhoneNumber"
                disabled
            />
        </div>
        <div class="col-md-4">
            <textarea
                class="delivery-field delivery-comments-textarea"
                :value="deliveryComment"
                @input="setDeliveryInfo('comment', $event.target.value)"
                name="commentsTextarea"
                placeholder="Комментарий"
            ></textarea>
            <div class="d-md-none">
                <input
                    type="checkbox"
                    class="order-urgent-checkbox"
                    name="isOrderUrgentInput"
                    :value="deliveryIsOrderUrgent"
                    @change="
                        setDeliveryInfo('isOrderUrgent', $event.target.checked)
                    "
                />
                <label for="isOrderUrgent" class="order-urgent-checkbox-label"
                    >Срочный заказ?</label
                >
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import DangerIcon from "@/components/visit/DangerIcon.vue";

    export default {
        name: 'CartDeliveryInfo',
      components: {DangerIcon},
        computed: {
            ...mapGetters('cart/subdivision', [
                'deliveryType',
                'deliveryRegion',
                'deliveryCity',
                'deliveryAddress',
                'deliveryStreet',
                'deliveryHouse',
                'deliveryRecipientName',
                'deliveryRecipientEmail',
                'deliveryIsOrderUrgent',
                'deliveryContactNumber',
                'deliveryOptionalContactNumber',
                'deliveryOrderPurpose',
                'deliveryOrderOrganization',
                'deliveryComment',
                'cartLines',
                'longTermLines',
                'splitOrder',
            ]),
            ...mapGetters('cart/subdivision', [
                'isSelectedDeliveryToOtherRegion',
                'isSelectedDeliveryToTheOffice',
                'isSelectedDeliveryToTheOrganization',
            ]),
            ...mapGetters('cart/subdivision', ['userEmail', 'userPhoneNumber']),
            isSplitOrder() {
                return this.cartLines.length && this.longTermLines.length;
            }
        },
        methods: {
            ...mapMutations('cart/subdivision', [
                'SET_DELIVERY_INFO',
                'SET_USER_EMAIL',
                'SET_USER_PHONE_NUMBER',
                'SET_DELIVERY_ADDRESS'
            ]),
            setDeliveryInfo(key, value) {
                this.SET_DELIVERY_INFO({ [key]: value });
                if (this.deliveryStreet && this.deliveryHouse) {
                    let adress = this.deliveryStreet + ', ' + this.deliveryHouse
                    this.SET_DELIVERY_ADDRESS(adress)
                }
            },
            isFieldEmpty(fieldValue) {
                return fieldValue != null && fieldValue == '';
            },
            isPhoneNumberSpecifiedCorrectly(phoneNumber) {
                return phoneNumber == null || phoneNumber.length >= 10;
            },
        },
    };
</script>

<style scoped>
    /* поля для типа доставки */
    .long-term__message {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #D2233C;
      & svg {
        min-width: 20px;
      }
    }
    .long-term__title{
        font-weight: bold;
        font-size: 16px;
    }

    .delivery-field {
        display: block;
        width: 100%;
        border: 1px solid #e1e2e7;
        border-radius: 4px !important;
        outline: none !important;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .notify-note {
        font-size: 12px;
        line-height: normal;
        margin-bottom: 0;
    }

    .icon-error {
        display: inline-block;
        background-repeat: no-repeat;
        flex-shrink: 0;
        width: 26px;
        height: 26px;
    }

    .icon-error {
        background-image: url('../../assets/images/alarm.svg');
    }

    .delivery-text-input {
        height: 40px;
        padding-left: 10px;
    }

    .delivery-comments-textarea {
        height: 200px;
        padding: 10px;
    }

    .delivery-field-required {
        border: 1px solid red !important;
    }

    .delivery-field-required::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: red;
        opacity: 1; /* Firefox */
    }

    .delivery-field-required:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: red;
    }

    .delivery-field-required::-ms-input-placeholder {
        /* Microsoft Edge */
        color: red;
    }

    .order-urgent-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
    }

    .order-urgent-checkbox:checked + .order-urgent-checkbox-label {
        background: url(../../assets/images/structural-subdivision-user-order/checked.png)
            no-repeat 2px 1px;
    }

    .order-urgent-checkbox-label {
        padding: 0 0 0 30px;
        margin: 0 !important;
        text-align: left !important;
        text-indent: 5px !important;
        background: url(../../assets/images/structural-subdivision-user-order/unchecked.png)
            no-repeat 2px 1px;
        cursor: pointer;
        color: #757575;
    }

    .order-urgent-checkbox-label:hover {
        color: black;
    }

    .order-urgent-checkbox-label::before {
        display: none !important;
    }

    .vertical-line {
        height: 3px;
        background-color: #eff1f6;
        margin-bottom: 12px;
        border-radius: 20px;
    }
</style>
