<template>
  <fragment>
    <div @click="quantity == 1 ? deleteLine({productId, sizeId}) : removeProductUpdateLine({productId, sizeId})" class="minus">
      <img src="@/assets/images/minus.svg">
    </div>
    <input @input="onQuantityChange($event.target.value)" :value="quantity" type="number" min="1" name="quantityInput" class="mobile-quantity"/>
    <div @click="addProductUpdateLine({productId, sizeId})" class="plus">
      <img src="@/assets/images/plus.svg">
    </div>
  </fragment>
</template>

<script>
import {mapActions} from "vuex";
const debounce = require('lodash.debounce');

export default {
  name: "CartLineProductQuantityMobile",
  props: {
    productId: {
      type: String
    },
    sizeId: {
      type: Number
    },
    quantity: {
      type: Number
    }
  },
  methods: {
    ...mapActions('cart/subdivision', ['addProductUpdateLine', 'removeProductUpdateLine', 'deleteLine', 'changeQuantity']),
    onQuantityChange: debounce(function (quantity) {
      this.changeQuantity({productId: this.productId, sizeId: this.sizeId, quantity});
    }, 500)
  }
}
</script>

<style scoped>
.minus, .plus {
  width: 25px;
  height: 100%;
  cursor: pointer;
  padding: 4px 0px;
}

@media (max-width: 767px) {
  .minus, .plus {
    float: left;
    display: inline-block;
    padding: 0;
  }
}

.mobile-quantity {
  width: 40px;
  height: 25px;
  text-align: center;
  float: left;
}
</style>